export default {
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
    'mobileSave.uid'() {
      this.$set(this.eventScroll, 'isSave', this.mobileSave.bol)
    },
  },
  methods: {
  },
}
